import React from 'react';
import { 
    DeleteOutlined, 
    PlusOutlined, 
    EditOutlined, 
    SearchOutlined,
    CloseOutlined,
    CheckOutlined, 
    WarningOutlined, 
    SaveOutlined 
} from '@ant-design/icons';

export const DeleteIcon = ({...rest}) => <DeleteOutlined {...rest}/>
export const UpdateIcon = ({...rest}) => <EditOutlined {...rest}/>
export const FilterIcon = ({...rest}) => <SearchOutlined {...rest}/>
export const CreateIcon = ({...rest}) => <PlusOutlined {...rest}/>
export const CancelIcon = ({...rest}) => <CloseOutlined {...rest}/>
export const ConfirmIcon = ({...rest}) => <CheckOutlined {...rest}/>
export const AlertIcon = ({...rest}) => <WarningOutlined {...rest}/>
export const SaveIcon = ({...rest}) => <SaveOutlined {...rest}/>
