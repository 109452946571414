import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { message } from 'antd';
import { AuthService } from '../services';
import Cookies from 'js-cookie';
import { JwtHelper, ToastHelper } from '../helpers';

export const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {
    const [token, setToken] = useState("");
    const [ isLogged, setIsLogged ] = useState(false);
    const [ type, setType ] = useState("");
    const navigate = useNavigate();

    const isLoginPath = () => window.location.pathname === '/';

    const redirect = (ptype) => ptype === 'City' ? navigate('/enterprises') : navigate('/election/votes');

    useEffect(() => {
        const token = Cookies.get(process.env.REACT_APP_COOKIE_TOKEN);
        if (token)
        {
            const token_type = JwtHelper.getJwtKeyValue(token, "type");
            setToken(token);
            setIsLogged(true);
            setType(token_type);

            if (isLoginPath()) redirect(token_type);

            return;
        }
        else logoff();

    },     
    // eslint-disable-next-line    
    []);

    async function login (login, password)
    {
        try {
            const response = await AuthService.login(login, password);

            const token = response.data.value;    
            const token_type = JwtHelper.getJwtKeyValue(token, "type");
            
            setToken(token);
            setIsLogged(true);
            setType(token_type);

            Cookies.set(process.env.REACT_APP_COOKIE_TOKEN, token, {expires: 1 });        
            redirect(token_type);

        } catch (er)
        {
            ToastHelper.showErrors(message, er, "Ocorreu um problema para efetuar o login");
        }
    }
    
    function logoff() {
        setToken('');        
        setIsLogged(false);
        setType('');
        Cookies.remove(process.env.REACT_APP_COOKIE_TOKEN);
        navigate('/');
    }

    return (
        <AuthContext.Provider value={{ 
                token, 
                isLogged,
                type,
                login,
                logoff
            }}>
            { children }
        </AuthContext.Provider>
    )
}