
export default {
    showErrors: (message, error, defaultMessage) => 
    {
        if (error.response.data?.errors && error.response.data?.errors.length)
            for (let i = 0; i < error.response.data?.errors.length; i++) {
                message.error(error.response.data?.errors[i])                
            }
        else 
            message.error(defaultMessage);
    }
}