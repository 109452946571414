import React  from 'react';
import { Layout } from 'antd';
import { AppTitlePage, AppHeader} from './';

const { Content } = Layout;

function VoteLayout({children}) 
{
    return (
        <Layout>
            <AppHeader></AppHeader>
            <Layout>        
            <Layout style={{ padding: '0 8px 8px' }}>
                <AppTitlePage></AppTitlePage>
                <Content style={{
                    padding: '8px',
                    margin: 0,
                    minHeight: 768,
                    background: "#fff",
                }}>
                    {children}
                </Content> 
            </Layout>           
            </Layout>     
        </Layout>
    );
}

export default VoteLayout;
