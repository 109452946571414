import React, { useState } from "react"
import { Modal, Form, Input, message } from 'antd';
import { AppFormItem } from "./"
import { CancelIcon, SaveIcon } from "./Icons";
import { ToastHelper } from "../helpers";
import { AuthService } from '../services';

const ChangeEmailModal = ({isOpen, onCancel, onAfterOperation, ...rest}) => 
{
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const onSaveHandler = () => form
        .validateFields()
        .then(async (values) => {
            try 
            {         
                setLoading(true);
                await AuthService.forgetPassword(values.login);    
                message.success('Email enviado com sucesso');                
                onAfterOperation();   
            } 
            catch (error) {
                ToastHelper.showErrors(message, error, "Ocorreu um problema para enviar o email"); 
            }
            finally
            {
                setLoading(false)
            }
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    const onCancelHandler = () => {
       form.resetFields();
       onCancel();
    }

    return (    
        <Modal 
            title="Esqueceu sua senha ?" 
            open={isOpen}            
            onOk={onSaveHandler}
            onCancel={onCancelHandler}
            okText="Enviar"
            okButtonProps={{
                size: "small",
                icon: <SaveIcon/>,
                loading: loading
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar">
             <Form 
                form={form}
                layout="vertical"
                name="form_forget_password"
                initialValues={ { email : "" } }
                {...rest}>

                <AppFormItem
                    name="login"
                    rules={[{ required: true, message: 'Por favor informe seu CNPJ' }]}
                    >
                    <Input size="small" placeholder="Informe seu CNPJ"/>
                </AppFormItem>
            </Form>
        </Modal>       
    )
}

export default ChangeEmailModal;