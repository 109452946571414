import React, { useState, useContext, useEffect } from "react"
import { message, Input } from "antd";
import { PageHeader, AppTable, AppButton } from '../../components';
import { UpdateIcon } from '../../components/Icons';
import { LayoutContext } from '../../contexts/LayoutContext';
import { EnterpriseService } from "../../services";
import { ToastHelper } from "../../helpers";
import { debounce } from 'lodash';

const EnterpriseQueryPage = () => 
{
    const [loading, setLoading] = useState(false);
    const [ data, setData ] = useState([]);
    const [ search, setSearch ] = useState('');
    const [ page, setPage ] = useState(1);
    const [ totalOfPages, setTotalOfPages ] = useState(0);
    const { setTitleAndSubTitle } = useContext(LayoutContext);

    const columns = [
        {
            title: '#Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Razão Social',
            dataIndex: 'companyName',
            key: 'companyName',
        },
        {
            title: 'Fantasia',
            dataIndex: 'fantasyName',
            key: 'fantasyName',
        },        
        {
            title: 'CNPJ',
            dataIndex: 'document',
            key: 'document',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key : 'email'
        }
    ];

    const loadData = debounce(async () => {
        try 
        {
            setLoading(true);   
            const { data } = await EnterpriseService.get(search, page);
            setData(data.value.values);
            setTotalOfPages(data.value.total);
        } 
        catch(error) 
        {
            ToastHelper.showErrors(message, error, "Ocorreu uma falha para consultar as empresas");             
        }
        finally{
           setLoading(false)
        }
    }, 500);

    useEffect(() => {
        setTitleAndSubTitle('Empresas', 'Empresas dos candidatos da eleição');
    }, [setTitleAndSubTitle]);

    useEffect(() => {
        loadData();
    },     
    // eslint-disable-next-line
    [page, search]);
    
    return (
        <>
            <PageHeader>
                <Input placeholder="Pesquisar" value={search} onChange={(e)=> {
                    setPage(1);
                    setSearch(e.target.value);
                }} />
            </PageHeader>
            <AppTable             
                rowKey={record => record.id}
                loading={loading} 
                columns={columns} 
                data={data}
                totalItems={totalOfPages}
                onPageChange={({current}) => setPage(current)}/>           
        </>
    )
}

export default EnterpriseQueryPage;