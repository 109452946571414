import { http } from './';

export default {
    get : (search, page) => http.get(`election?search=${search}&pageSize=20&page=${page}`),
    getActive: () => http.get(`election/active`),
    create: (model) => http.post('election', model),
    update: (id, model) => http.put(`election/${id}`, model),
    addCandidate : (id, model) => http.post(`election/${id}/candidates`, { name : model.name}),
    getCandidates : (electionId, search, page) => http.get(`election/${electionId}/candidates?search=${search}&pageSize=20&page=${page}`),
    getEnterpriseVotes: (electionId) => http.get(`election/${electionId}/enterprise-votes`),
    vote : (electionId, candidateId) => http.post(`election/${electionId}/vote?`, { candidateId }),
    results : (electionId, search, page) => http.get(`election/${electionId}/results?search=${search}&pageSize=20&page=${page}`),
}

