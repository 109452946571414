import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom'
import { VoteLayout, AnonymousLayout, LoggedLayout } from '../components';
import { LoginPage } from './auth';
import { AuthContext } from '../contexts/AuthContext';
import { ElectionCandidateQueryPage, ElectionVotePage, ElectionResultPage, ElectionQueryPage } from './election';
import { EnterpriseQueryPage } from './enterprise';

const AppRoutes = () => {

  const { isLogged, type }  = useContext(AuthContext);


  return (    
    <>
      { isLogged && type === 'Entrepreneur' ? 
        <VoteLayout>
          <Routes>  
            <Route path="/election/votes" element={<ElectionVotePage />} />           
          </Routes>
        </VoteLayout> : 
        isLogged && type === 'City' ? 
        <LoggedLayout>
          <Routes>  
            <Route path="/election" element={<ElectionQueryPage />} />  
            <Route path="/election/candidates/:id" element={<ElectionCandidateQueryPage />} />   
            <Route path="/enterprises" element={<EnterpriseQueryPage />} />          
            <Route path="/election/results/:id" element={<ElectionResultPage />} />   
          </Routes>
        </LoggedLayout> :
        <AnonymousLayout>
          <Routes>  
            <Route path="/" element={<LoginPage />} />                
          </Routes>
        </AnonymousLayout> 
      }
    </>)
}

export default AppRoutes