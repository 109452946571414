import React, { useState, useContext, useEffect } from "react"
import { message, Input } from "antd";
import { PageHeader, AppTable } from '../../components';
import { LayoutContext } from '../../contexts/LayoutContext';
import { ElectionService } from "../../services";
import { ToastHelper } from "../../helpers";
import { debounce } from 'lodash';
import { useParams } from "react-router";

const ElectionResultPage = () => 
{
    const [loading, setLoading] = useState(false);
    const [ data, setData ] = useState([]);
    const [ search, setSearch ] = useState('');
    const [ page, setPage ] = useState(1);
    const [ totalOfPages, setTotalOfPages ] = useState(0);
    const { setTitleAndSubTitle } = useContext(LayoutContext);
    const { id } = useParams();

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            align: 'left'
        },
        {
            title: 'Votos',
            dataIndex: 'votes',
            key: 'votes',
            align: 'right'
        }
    ];

    const loadData = debounce(async () => {
        try 
        {
            setLoading(true);   
            const { data } = await ElectionService.results(id, search, page);
            setData(data.value.values);
            setTotalOfPages(data.value.total);
        } 
        catch(error) 
        {
            ToastHelper.showErrors(message, error, "Ocorreu uma falha para consultar os resultados");             
        }
        finally{
           setLoading(false)
        }
    }, 500);

    useEffect(() => {
        setTitleAndSubTitle('Resultados', 'Resultado de votação da eleição');
    }, [setTitleAndSubTitle]);

    useEffect(() => {
        loadData();
    },     
    // eslint-disable-next-line
    [page, search]);
    
    return (
        <>
            <PageHeader>
                <Input placeholder="Pesquisar" value={search} onChange={(e)=> {
                    setPage(1);
                    setSearch(e.target.value);
                }} />
            </PageHeader>
            <AppTable             
                rowKey={record => record.id}
                loading={loading} 
                columns={columns} 
                data={data}
                totalItems={totalOfPages}
                onPageChange={({current}) => setPage(current)}/>                 
        </>
    )
}

export default ElectionResultPage;