import React, { useState, useContext, useEffect } from "react"
import { message, Divider, Row, Col, Pagination, Typography, Flex, Input  } from "antd";
import { PageHeader, AppButton } from '../../components';
import { ConfirmIcon } from '../../components/Icons';
import { LayoutContext } from '../../contexts/LayoutContext';
import { ElectionService } from "../../services";
import { ConfirmModalContext } from "../../contexts/ConfirmModalContext";
import { ToastHelper } from "../../helpers";
import { debounce } from 'lodash';


const VoteCard = ({item, loading, onVoteHandler}) => {
    return(
        <div
        style={{ 
            width: "100%", 
            margin: '4px',                     
            boxShadow: item.isVoted ? "0 4px 8px rgba(104, 196, 156, 0.2)" : '0 4px 8px rgba(0, 0, 0, 0.1)',
            border: item.isVoted ? "1px solid #68c49c" : "1px solid #dadada",
            padding: '8px',
            borderRadius: '8px' }}
    >
        <Flex vertical justify="space-between" style={{ height: '100%'}} >
            <div>
                <Typography.Text style={{display: "block", fontWeight:'bold', fontSize: '16px' }}>{item.name}</Typography.Text>
                {
                    item.enterprises.map((enterprise, index) => (
                    <Typography.Text
                        key={index}
                        style={{ display: 'block', fontSize: '12px', marginTop: '4px' }}
                    >
                        <Typography.Text style={{ fontWeight: 'bold' }}>
                        Empresa:
                        </Typography.Text>{' '}
                        {enterprise.fantasyName}
                    </Typography.Text>
                    ))
                }
            </div>
            <div>
                <Divider style={ { marginBottom: 6, marginTop: 6 }}></Divider>
                {
                    item.isVoted ?                     
                    <AppButton><ConfirmIcon/>Meu Voto</AppButton>
                    :
                    <AppButton 
                        color='default'
                        variant='outlined'
                        onClick={() => onVoteHandler(item)} loading={loading}>
                        <ConfirmIcon/> Votar
                    </AppButton>
                }
                
            </div>
        </Flex>                       
    </div>
    )
}

const ElectionVotePage = () => 
{
    const [loading, setLoading] = useState(false);
    const [ data, setData ] = useState([]);
    const [ search, setSearch ] = useState('');
    const [ page, setPage ] = useState(1);
    const [ enterpriseVotes, setEnterpriseVotes] = useState([]);
    const [ totalOfPages, setTotalOfPages ] = useState(0);
    const { setTitleAndSubTitle } = useContext(LayoutContext);
    const { executeIfConfirm} = useContext(ConfirmModalContext);
    const [ election, setElection] = useState(null);

    const canVote = () => data.filter(_ => _.isVoted).length < 3;

    const loadData = debounce(async (ppage, psearch) => {
        try 
        {
            setLoading(true);   
            const { data } = await ElectionService.getCandidates(election.id, psearch, ppage);
            setData(data.value.values);
            setTotalOfPages(data.value.total);
        } 
        catch(error) 
        {
            ToastHelper.showErrors(message, error, "Ocorreu uma falha para consultar os candidatos");             
        }
        finally{
           setLoading(false)
        }
    }, 500);

    const loadVotes = async () => {
        try 
        {
            setLoading(true);   
            const { data } = await ElectionService.getEnterpriseVotes(election.id);
            setEnterpriseVotes(data.value);
        } 
        catch(error) 
        {
            console.log(error);
            ToastHelper.showErrors(message, error, "Ocorreu uma falha para consultar os voto da empresa");             
        }
        finally{
           setLoading(false)
        }
    }

    const loadActiveElection = async () => {
        try 
        {
            setLoading(true);   
            const { data } = await ElectionService.getActive();            
            setTitleAndSubTitle('Votação', `Escolha até ${data.value.quantityVotes} candidatos para receber os votos`);
            setElection(data.value);
        } 
        catch(error) 
        {
            ToastHelper.showErrors(message, error, "Ocorreu uma falha para consultar os voto da empresa");             
        }
        finally{
           setLoading(false)
        }
    }

    const onVoteHandler = (candidate) => 
    {
        if (! canVote ())
        {
            message.error("Sua empresa já realizou os 3 votos")
            return;
        }

        executeIfConfirm("Atenção", "Tem certeza que deseja votar nesse candidato ?", async () => {
            try 
            {
                setLoading(true);   
                await ElectionService.vote(election.id, candidate.id);
                await loadData(page, search);       
                await loadVotes();         
            } 
            catch(error) 
            {
                ToastHelper.showErrors(message, error, "Ocorreu um problema para realizar a votação");           
            }
            finally
            {
                setLoading(false);  
            }
        })
    }

    useEffect(() => {  
        loadActiveElection();
    },     
    // eslint-disable-next-line
    []);

    useEffect(() => 
    {
        if (election)
        {
            loadVotes();
            loadData(page, search); 
        } 
    },     
    // eslint-disable-next-line
    [election])
        
    return (
        <>
            <PageHeader>
                <Input placeholder="Pesquisar" value={search} onChange={(e)=> {
                    setPage(1);
                    setSearch(e.target.value);
                    loadData(1, e.target.value); 
                }} />
            </PageHeader>
            <Row justify="start">
                {enterpriseVotes && enterpriseVotes.map((item) => (
                    <Col
                        key={item.id}
                        xs={24} // Para telas pequenas (mobile)
                        sm={12} // Para telas médias (tablet)
                        md={8}  // Para telas maiores (desktop)
                        lg={6}  // Para telas grandes (desktop grande)
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <VoteCard item={item} loading={loading} onVoteHandler={onVoteHandler}></VoteCard>
                    </Col>
                ))}

                {data && data.map((item) => (
                    <Col
                        key={item.id}
                        xs={24} // Para telas pequenas (mobile)
                        sm={12} // Para telas médias (tablet)
                        md={8}  // Para telas maiores (desktop)
                        lg={6}  // Para telas grandes (desktop grande)
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <VoteCard item={item} loading={loading} onVoteHandler={onVoteHandler}></VoteCard>
                    </Col>
                ))}
            </Row>     
            <Divider style={ { marginBottom: 6, marginTop: 6 }}></Divider>            
            <Pagination 
                align="end" 
                size="small" 
                defaultCurrent={1} 
                total={totalOfPages} 
                defaultPageSize={20}
                pageSize={20}
                current={page}
                showSizeChanger={false} 
                onChange={async (value) =>{
                    setPage(value);
                    loadData(value, search);
                }} />    
        </>
    )
}

export default ElectionVotePage;