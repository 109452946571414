import React, { useEffect, useState } from "react"
import { Modal, Input, message, Flex } from 'antd';
import { CreateIcon } from "../../components/Icons";
import { CandidateService, ElectionService } from "../../services";
import { AppTable, PageHeader, AppButton } from "../../components";
import { debounce } from "lodash";
import { ToastHelper } from "../../helpers";

const ElectionModalForm = ({isOpen, electionId, onAfterOperation, ...rest}) => 
{
    const [loading, setLoading] = useState(false);
    const [ data, setData ] = useState([]);
    const [ search, setSearch ] = useState('');
    const [ page, setPage ] = useState(1);
    const [ totalOfPages, setTotalOfPages ] = useState(0);

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '-',
            dataIndex: 'id',
            key: 'action',
            align: 'right',
            render: (_, record) =>(
                <>
                    <AppButton onClick={async () => onSaveHandler(record)}><CreateIcon/></AppButton>
                </>
            )
        }
    ];

    const onSaveHandler = async (record) => {
        try 
        {           
            await ElectionService.addCandidate(electionId, record);            
            message.success('Operação realizada com sucesso');
            onAfterOperation();   
        } 
        catch (error) {
            message.error('Ocorreu uma falha para realizar operação');
            console.log(error);    
        }
    }
        
    const loadData = debounce(async () => {
        try 
        {
            setLoading(true);   
            const { data } = await CandidateService.get(search, page);
            setData(data.value.values);
            setTotalOfPages(data.value.total);
        } 
        catch(error) 
        {
            ToastHelper.showErrors(message, error, "Ocorreu uma falha para consultar os candidatos");             
        }
        finally{
           setLoading(false)
        }
    }, 500)

    useEffect(() => {
        if (isOpen) loadData();
    }, [isOpen, search, page]);
    
    return (        
        <Modal 
            title="Selecione um Candidato" 
            open={isOpen}            
            onCancel={() => onAfterOperation()}
            footer={null}>

            <PageHeader>
                <Input placeholder="Pesquisar" value={search} onChange={(e)=> {
                    setPage(1);
                    setSearch(e.target.value);
                }} />
            </PageHeader>
            <AppTable             
                rowKey={record => record.id}
                loading={loading} 
                columns={columns} 
                data={data}
                totalItems={totalOfPages}
                onPageChange={({current}) => setPage(current)}/>  

        </Modal>
       
    )
}

export default ElectionModalForm;