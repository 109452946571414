import React, { useState, useContext, useEffect } from "react"
import { message, Input, Typography, Flex } from "antd";
import { PageHeader,  AppTable, PageFilter, AppButton,  } from '../../components';
import { LayoutContext } from '../../contexts/LayoutContext';
import { ElectionService } from "../../services";
import { ToastHelper } from "../../helpers";
import { debounce } from 'lodash';
import { useParams } from "react-router";
import ElectionModalForm from "./ElectionCandidateModalForm";
import { CreateIcon, FilterIcon } from "../../components/Icons";

const ElectionCandidateQueryPage = () => 
{

    const [loading, setLoading] = useState(false);
    const [ data, setData ] = useState([]);
    const [ search, setSearch ] = useState('');
    const [ page, setPage ] = useState(1);
    const [ totalOfPages, setTotalOfPages ] = useState(0);
    const { setTitleAndSubTitle } = useContext(LayoutContext);
    const { id } = useParams();
    const [ openCandidateModal, setOpenCandidateModal ] = useState(false);
    const [ isFilterVisible, setIsFilterVisible ] = useState(true);

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Empresas',
            dataIndex: 'enterprises',
            key : 'enterprises',
            render: (enterprises) => (
                <>
                  {enterprises.map((enterprise, index) => (
                    <Typography.Text
                      key={index}
                      style={{ display: 'block', fontSize: '12px', marginTop: '4px' }}
                    >
                      <Typography.Text style={{ fontWeight: 'bold' }}>
                        Empresa:
                      </Typography.Text>{' '}
                      {enterprise.fantasyName}
                    </Typography.Text>
                  ))}
                </>
              ),
        }
    ];

    const loadData = debounce(async () => {
        try 
        {
            setLoading(true);   
            const { data } = await ElectionService.getCandidates(id, search, page);
            setData(data.value.values);
            setTotalOfPages(data.value.total);
        } 
        catch(error) 
        {
            ToastHelper.showErrors(message, error, "Ocorreu uma falha para consultar os candidatos");             
        }
        finally{
           setLoading(false)
        }
    }, 500);

    useEffect(() => {
        setTitleAndSubTitle('Candidatos', 'Gerenciamento dos candidatos da eleição');
    }, [setTitleAndSubTitle]);

    useEffect(() => {
        loadData();
    },     
    // eslint-disable-next-line
    [page, search]);


        
    return (
        <>

            <PageHeader>
                <AppButton onClick={() => setIsFilterVisible(! isFilterVisible) }><FilterIcon/>Filtros</AppButton>
                <AppButton onClick={() => setOpenCandidateModal(true) } ><CreateIcon/>Cadastro</AppButton>

                <PageFilter isVisible={isFilterVisible}>
                    <Input placeholder="Pesquisar" value={search} onChange={(e)=> {
                            setPage(1);
                            setSearch(e.target.value);
                        }} />
                </PageFilter>
            </PageHeader>

            <AppTable             
                rowKey={record => record.id}
                loading={loading} 
                columns={columns} 
                data={data}
                totalItems={totalOfPages}
                onPageChange={({current}) => setPage(current)}/>   

            <ElectionModalForm 
            isOpen={openCandidateModal} 
            electionId={id}
            onAfterOperation={() => 
            {
                setOpenCandidateModal(false);
                loadData();
            }}></ElectionModalForm>                  
        </>
    )
}

export default ElectionCandidateQueryPage;