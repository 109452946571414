import React, { useState } from "react"
import { Modal, Form, Input, message } from 'antd';
import { AppFormItem } from "./"
import { CancelIcon, SaveIcon } from "./Icons";
import { ToastHelper } from "../helpers";
import { EnterpriseService } from '../services';

const ChangeEmailModal = ({isOpen, onCancel, onAfterOperation, currentRecord, ...rest}) => 
{
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const onSaveHandler = () => form
        .validateFields()
        .then(async (values) => {
            try 
            {         
                setLoading(true);
                await EnterpriseService.changeEmail(values.email);    
                message.success('Email atualizado com sucesso');                
                onAfterOperation();   
            } 
            catch (error) {
                ToastHelper.showErrors(message, error, "Ocorreu um problema para atualiza o email"); 
            }
            finally
            {
                setLoading(false)
            }
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    const onCancelHandler = () => {
       form.resetFields();
       onCancel();
    }

    return (    
        <Modal 
            title="Atualizar seu Email" 
            open={isOpen}            
            onOk={onSaveHandler}
            onCancel={onCancelHandler}
            okText="Salvar"
            okButtonProps={{
                size: "small",
                icon: <SaveIcon/>,
                loading: loading
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar">
                { 
                    currentRecord?.currentEmail ? 
                    <Form 
                        form={form}
                        layout="vertical"
                        name="form_change_email"
                        initialValues={ { email : currentRecord.currentEmail } }
                        {...rest}>

                        <AppFormItem
                            name="email"
                            rules={[{ required: true, message: 'Por favor informe seu email' }]}
                            >
                            <Input size="small" placeholder="Informe seu email"/>
                        </AppFormItem>
                    </Form>
                    :                   
                    <Form 
                        form={form}
                        layout="vertical"
                        name="form_change_email"
                        initialValues={ { email : '' } }
                        {...rest}>

                        <AppFormItem
                            name="email"
                            rules={[{ required: true, message: 'Por favor informe seu email' }]}
                            >
                            <Input size="small" placeholder="Informe seu email"/>
                        </AppFormItem>
                    </Form>
                }
        </Modal>       
    )
}

export default ChangeEmailModal;