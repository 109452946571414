export default {
    getJwtKeyValue : (token, key) => 
    {
        try 
        {
            const [, payloadBase64] = token.split('.');
            const payload = JSON.parse(atob(payloadBase64));
            return payload[key];
        } catch (error) {
            console.error('Invalid JWT or key not found:', error);
            return null;
        }
    }
}