import React from 'react';
import { Form } from 'antd';

const AppFormItem = ({children, ...rest}) => {
    return (
        <Form.Item style={{ margin: 0, marginBottom: 6}} {...rest}>
            {children}
        </Form.Item>
    )
}

export default AppFormItem;