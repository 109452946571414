import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd';
import { LayoutContextProvider } from './contexts/LayoutContext';
import { AuthContextProvider } from './contexts/AuthContext';
import { ConfirmModalContextProvider } from './contexts/ConfirmModalContext';
import { ConfirmModal } from './components';
import AppRoutes from './pages/AppRoutes';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <LayoutContextProvider>
          <ConfirmModalContextProvider>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: '#0a7a49',
                },
              }}>
                <AppRoutes></AppRoutes>
                <ConfirmModal></ConfirmModal>
            </ConfigProvider>
          </ConfirmModalContextProvider>
        </LayoutContextProvider>
      </AuthContextProvider>
    </BrowserRouter>

  );
}

export default App;
