import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';

const { Sider } = Layout;

const AppSiderbar = () => {
  const [ collapsedSidebar] = useState(false);
  const [currentKey, setCurrentKey ] = useState('1');
  const navigate = useNavigate();

  const menuItems = [    
    {
      key : '1',
      icon: <SolutionOutlined/>,
      label : 'Empresas',
      path : '/enterprises'
    },   
    {
      key : '2',
      icon: <SolutionOutlined/>,
      label : 'Eleições',
      path : '/election'
    }
  ];

  const onClickHandler = (item) => {
    setCurrentKey(item.key);
    const clicked = menuItems.find(_item => _item.key === item.key);
    navigate(clicked.path)
  }

  return (
    <Sider 
        trigger={null} 
        collapsible 
        collapsed={ collapsedSidebar } 
        width={250}
        theme="light"
        >        
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={currentKey}
          defaultSelectedKeys={['1']}
          items={menuItems}
          onClick={onClickHandler}
        >
        </Menu>
  </Sider>
  );
};

export default AppSiderbar;