import React from "react"
import { Modal, Form, Input, message, Row, Col, DatePicker } from 'antd';
import { AppFormItem, AppSelect } from "../../components"
import { CancelIcon, SaveIcon } from "../../components/Icons";
import { ElectionService } from "../../services";
import dayjs from "dayjs";

const ElectionModalForm = ({isOpen, currentRecord, onCancel, onAfterOperation, ...rest}) => 
{
    const [form] = Form.useForm();

    const onSaveHandler = () => form
        .validateFields()
        .then(async (values) => {
            try 
            {           
                const mappedValues = {
                    ... values, 
                    startDate : values.startDate.add(-3, "hours"),
                    endDate : values.endDate.add(-3, "hours")
                }
                console.log(mappedValues);
                currentRecord ? await ElectionService.update(currentRecord.id, mappedValues) : await ElectionService.create(mappedValues);              
                
                form.resetFields();
                message.success('Operação realizada com sucesso');
                onAfterOperation();   
            } 
            catch (error) {
                message.error('Ocorreu uma falha para realizar operação');
                console.log(error);    
            }
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });

    const onCancelHandler = () => {
       form.resetFields();
       onCancel();
    }
    
    return (        
        <Modal 
            title="Cadastro de Categoria" 
            open={isOpen}            
            onOk={onSaveHandler}
            onCancel={onCancelHandler}
            okText="Salvar"
            okButtonProps={{
                size: "small",
                icon: <SaveIcon/>
            }}
            cancelButtonProps={{
                size: "small",
                icon: <CancelIcon/>
            }}
            cancelText="Cancelar">
                <Form 
                    form={form}
                    layout="vertical"
                    name="form_category_filter"
                    initialValues={currentRecord ? 
                        {...currentRecord, startDate : dayjs(currentRecord.startDate), endDate: dayjs(currentRecord.endDate)} : 
                        { name : '', startDate : "", endDate: "", status : 1 }}
                    {...rest}>
                    <Row>
                        <Col span={24} style={{ paddingLeft: 2, paddingRight: 2}}>
                            <AppFormItem
                            name="name"
                            label="Nome" 
                            rules={[{ required: true, message: 'Por favor informe o nome da eleição' }]}
                            >
                                <Input size="small" placeholder="Informe o nome da eleição"/>
                            </AppFormItem>
                        </Col>  

                        <Col span={8} style={{ paddingLeft: 2, paddingRight: 2}}>
                            <AppFormItem
                                name="startDate"
                                label="Data de inicio" 
                                rules={[{ required: true, message: 'A data inicial não foi informada' }]}
                                >
                                <DatePicker placeholder="01/01/2024" showTime size="small"  />
                            </AppFormItem>
                        </Col>

                        <Col span={8} style={{ paddingLeft: 2, paddingRight: 2}}>
                            <AppFormItem
                                name="endDate"
                                label="Data de Final" 
                                rules={[{ required: true, message: 'A data final não foi informada' }]}
                                >
                                <DatePicker placeholder="01/01/2024" showTime size="small"  />
                            </AppFormItem>
                        </Col>

                        <Col span={8} style={{ paddingLeft: 2, paddingRight: 2}}>
                            <AppFormItem
                            name="quantityVotes"
                            label="Qtdade Votos" 
                            rules={[{ required: true, message: 'Por favor informe a quantidade de fotos' }]}
                            >
                                <Input size="small" type="number" placeholder="Informe o quantidade de votos"/>
                            </AppFormItem>
                        </Col>  

                        <Col span={8} style={{ paddingLeft: 2, paddingRight: 2}}>
                            <AppFormItem
                                name="status"
                                label="Status" 
                                >
                                <AppSelect disabled={! currentRecord} size="small" options={[ { value: 1, label: "Ativo" }, { value: 2, label: "Inativo" }]}></AppSelect>
                            </AppFormItem>                            
                        </Col>                     
                    </Row>    
                </Form>
        </Modal>
       
    )
}

export default ElectionModalForm;