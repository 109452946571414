import React, { useState, useContext, useEffect } from "react"
import { message, Input, Typography } from "antd";
import { PageHeader,  AppTable, AppButton, PageFilter,  } from '../../components';
import { LayoutContext } from '../../contexts/LayoutContext';
import { ElectionService } from "../../services";
import { ToastHelper } from "../../helpers";
import { debounce } from 'lodash';
import { UserOutlined } from "@ant-design/icons";
import { CreateIcon, FilterIcon, UpdateIcon } from "../../components/Icons";
import ElectionModalForm from "./ElectionModalForm";
import { useNavigate } from "react-router-dom"

const ElectionQueryPage = () => 
{

    const [ isFilterVisible, setIsFilterVisible ] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ data, setData ] = useState([]);
    const [ search, setSearch ] = useState('');
    const [ page, setPage ] = useState(1);
    const [ totalOfPages, setTotalOfPages ] = useState(0);
    const { setTitleAndSubTitle } = useContext(LayoutContext);
    const [ isOpenFormModal, setIsOpenFormModal ] = useState(false);
    const [ seletectedRecord, setSeletectedRecord ] = useState(null);
    
    const navigate = useNavigate();

    const columns = [
        {
            title :"#id",
            dataIndex: "id",
            key: 'id'
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '-',
            dataIndex: 'id',
            key: 'action',
            align: 'right',
            render: (_, record) =>(
                <>
                    <AppButton onClick={async () => await updateHandler(record)}><UpdateIcon/></AppButton>
                    <AppButton onClick={async () => navigate(`/election/candidates/${record.id}`)}><UserOutlined/>Candidatos</AppButton>
                    <AppButton onClick={() => navigate(`/election/results/${record.id}`)}><UserOutlined/>Resultado</AppButton>
                </>
            )
        }
    ];

    const loadData = debounce(async () => {
        try 
        {
            setLoading(true);   
            const { data } = await ElectionService.get(search, page);
            setData(data.value.values);
            setTotalOfPages(data.value.total);
        } 
        catch(error) 
        {
            ToastHelper.showErrors(message, error, "Ocorreu uma falha para consultar os candidatos");             
        }
        finally{
           setLoading(false)
        }
    }, 500);

    useEffect(() => {
        setTitleAndSubTitle('Eleições', 'Gerenciamento das eleição');
    }, [setTitleAndSubTitle]);

    useEffect(() => {
        loadData();
    },     
    // eslint-disable-next-line
    [page, search]);

    const resetCrud = async () => {        
        await setSeletectedRecord(null);
        await setIsOpenFormModal(false);
    }

    const onAfterOperationHandler = async () => {
        await resetCrud();
        await loadData();
    }     
    
    const createHandler = async () => {
        await setIsOpenFormModal(true);
    }

    const updateHandler = async (record) => {
        await setSeletectedRecord(record);
        await setIsOpenFormModal(true);
    }
        
    return (
        <>
            <PageHeader>
                <AppButton onClick={() => setIsFilterVisible(! isFilterVisible) }><FilterIcon/>Filtros</AppButton>
                <AppButton onClick={() => createHandler() } ><CreateIcon/>Cadastro</AppButton>

                <PageFilter isVisible={isFilterVisible}>
                    <Input placeholder="Pesquisar" value={search} onChange={(e)=> {
                        setPage(1);
                        setSearch(e.target.value);
                    }} />
                </PageFilter>
            </PageHeader>
            <AppTable             
                rowKey={record => record.id}
                loading={loading} 
                columns={columns} 
                data={data}
                totalItems={totalOfPages}
                onPageChange={({current}) => setPage(current)}/>           
                {
                    isOpenFormModal  ?
                    <ElectionModalForm                        
                    isOpen={isOpenFormModal}
                    currentRecord={seletectedRecord}
                    onCancel={async () => await resetCrud() }
                    onAfterOperation={async () => await onAfterOperationHandler()}>                        
                    </ElectionModalForm> : <></>
                }      
        </>
    )
}

export default ElectionQueryPage;