     

import React, { useContext, useEffect, useState } from 'react';
import { Layout, Dropdown, Button, message } from 'antd';
import { DownOutlined, LockOutlined, MailOutlined, PoweroffOutlined } from '@ant-design/icons';
import { ChangeEmailModal, ChangePasswordModal } from './';
import { AuthContext } from '../contexts/AuthContext';
import { EnterpriseService } from '../services';
import { ToastHelper } from '../helpers';
import { debounce } from 'lodash';

const { Header } = Layout;

function AppHeader() {
    const [openChangeEmailModal, setOpenChangeEmailModal] = useState(false);
    const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
    const [needChangeEmail, setNeedChangeEmail] = useState({needChange : false, currentEmail : ''});
    const { logoff } = useContext(AuthContext)

    const menuProps = {
        items : [
        {
            label: 'Atualizar Email',
            key: '1',
            icon: <MailOutlined />,
        },
        {
            label: 'Troca de Senha',
            key: '2',
            icon: <LockOutlined />,
        },
        {
            label: 'Sair',
            key: '3',
            icon: <PoweroffOutlined />
        }
        ],
        onClick: ({key}) => key === '1' ? setOpenChangeEmailModal(true) : 
                            key === '2' ? setOpenChangePasswordModal(true) : 
                            logoff(),
      };

    const validateIfNeedChangePassword = debounce(async () => {
        try 
        {
            const { data }  = await EnterpriseService.needChangeEmail();
            setNeedChangeEmail(data.value);
            setOpenChangeEmailModal(data.value.needChange)
        } 
        catch (error)
        {
            ToastHelper.showErrors(message, error, "Ocorreu um problema para validar se precisa alteração de email");
        }
    }, 500);  

    useEffect(() => {
        validateIfNeedChangePassword();
    },     
    // eslint-disable-next-line
    [])  

  return (
    <Header 
        style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#0a7a49",
            padding: "0 20px",
        }}>                            
        <div style={{ color: "#fff", fontSize: "18px", fontWeight: "bold" }}>
            ACIX
        </div>

        <Dropdown
            menu={menuProps}
            placement="bottomRight"
            trigger={['click']}
            arrow
        >
            <Button size='normal' style={{borderRadius : '50px', padding: '12px 10px 12px 10px'}}><DownOutlined /></Button>
        </Dropdown>

        <ChangeEmailModal
            isOpen={openChangeEmailModal} 
            currentRecord={needChangeEmail}
            onCancel={() => setOpenChangeEmailModal(false)}
            onAfterOperation={() => setOpenChangeEmailModal(false)}>
        </ChangeEmailModal>    

        <ChangePasswordModal
            isOpen={openChangePasswordModal} 
            onCancel={() => setOpenChangePasswordModal(false)}
            onAfterOperation={() => setOpenChangePasswordModal(false)}>
        </ChangePasswordModal>   
    </Header>  
  );
}

export default AppHeader;
