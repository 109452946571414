import React from 'react';
import {Table} from 'antd';

const AppButton = ({columns, data, loading, onPageChange, totalItems, ...rest}) => {
    return(
        <Table             
            locale={{ emptyText: 'Nenhum registro foi localizado' }}
            loading={loading} 
            size="small" 
            columns={columns} 
            dataSource={data}               
            pagination={{
                defaultPageSize: 20, 
                pageSize: 20,
                total: totalItems,
                position: ['bottomRight'],
                showSizeChanger: false,
            }}            
            onChange={onPageChange}
        { ...rest }/>
    )
};

export default AppButton;